<template>
 <div class="he-ui-scan-selection-wrapper d-flex flex-column justify-content-center align-items-start h-100">

  <div class="text-start he-ui-font-normal-bold mb-4">
    {{ $t('main.select_hand_and_finger_to_scan') }}:
  </div>

  <div class="he-ui-scan-fingers-hands-mini mb-4">
    <div
      v-for="(hand) in hands"
      :key="hand.value"
      :class="{'hand-selected': selectedHand === hand.value}"
      class="he-ui-scan-fingers-hand-img"
      @click="onClickSelectHand(hand.value)">
      <img
        :src="hand.src"
        :alt="hand.alt" />
        <div class="mt-3">{{ hand.text }}</div>
    </div>
  </div>

  <div class="he-ui-scan-fingers-buttons">
    <div
      v-for="finger in fingers"
      :key="finger.index"
      class="he-ui-scan-fingers-button d-flex align-items-center justify-content-between"
      :class="{'selected': isFingerSelected(finger.index), 'done': isFingerDone(finger.index), 'finger-disabled': !isFingerSelectionEnable(finger.index)}"
      @click="isFingerSelectionEnable(finger.index) ? onClickSelectFinger(finger.index) : null" >
      <div>{{ finger.text }}</div>
      <div class="d-flex flex-row">
        <div v-if="isIndexFinger(finger.frontendvalue)" class="he-ui-predefined-value me-1"><img :class="{'he-ui-star-done': isFingerDone(finger.index)}" :src="iconStarBlue" /></div>
        <div class="he-ui-scan-fingers-button-index">{{ finger.frontendvalue }}</div>
      </div>
    </div>
  </div>

  <div class="w-100 mt-4 d-flex">
    <Button
      v-if="isNewPatient"
      v-show="showReplaceButton"
      class="me-2 px-3"
      template="white"
      rounded
      uppercase
      :text="isReplaceEnabled ? $t('main.cancel') : $t('main.replace')"
      :onClick="onClickReplace" />
    <Button
      v-if="showScanButton"
      :class="{'w-100': !showEndbutton}"
      class="px-3"
      template="white"
      rounded
      uppercase
      :text="$t('main.scan')"
      :onClick="onClickScan" />
    <Button
      v-if="showEndbutton"
      class="ms-2 "
      template="blue"
      rounded
      uppercase
      :disabled="disableEndButton"
      :text="$t('main.end')"
      :onClick="onClickEnd" />
  </div>

</div>
</template>

<script>
import { LAST_INDEX_RIGHT_HAND } from '@/constants/constants'
import { mapGetters, mapMutations } from 'vuex'
import Button from '@/components/MainButton'
import imgStarBlue from '@/assets/star_blue.svg'

export default {
  components: {
    Button
  },
  props: {
    onScan: {
      type: Function,
      default: () => {}
    },
    onDiscard: {
      type: Function,
      default: () => {}
    },
    onSelectHand: {
      type: Function,
      default: () => {}
    },
    onSelectFinger: {
      type: Function,
      default: () => {}
    },
    isNewPatient: {
      type: Boolean,
      default: false
    },
    onClickEnd: {
      type: Function,
      default: () => {}
    },
    disableEndButton: {
      type: Boolean,
      default: false
    },
    savedFingers: {
      type: Array,
      default: () => []
    },
    fromReplace: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'getHandSelected',
      'getFingerSelected'
    ]),
    showEndbutton () {
      return this.isNewPatient && !this.disableEndButton
    },
    showReplaceButton () {
      return this.savedFingers.length > 0
    },
    showScanButton () {
      return (this.selectedFinger >= 0 && this.selectedFinger <= 9)
    }
  },
  data () {
    return {
      isComeFromReplace: this.fromReplace,
      isReplaceEnabled: false,
      selectedHand: null,
      selectedFinger: null,
      iconStarBlue: imgStarBlue,
      hands: [
        { src: require('../assets/left-hand.png'), alt: 'left_hand', text: this.$t('main.left_hand'), value: 0 },
        { src: require('../assets/right-hand.png'), alt: 'right_hand', text: this.$t('main.right_hand'), value: 1 }
      ],
      fingers: [
        { text: this.$t('main.indexfinger'), index: 1, frontendvalue: 1 },
        { text: this.$t('main.middlefinger'), index: 2, frontendvalue: 2 },
        { text: this.$t('main.ringfinger'), index: 3, frontendvalue: 3 },
        { text: this.$t('main.pinky'), index: 4, frontendvalue: 4 },
        { text: this.$t('main.thumb'), index: 0, frontendvalue: 5 }
      ]
    }
  },
  mounted () {
    this.onClickSelectHand(this.getHandSelected)
    if (!this.isComeFromReplace) {
      this.onClickSelectFinger(this.getFirstEnableFinger())
    } else {
      const finger = this.selectedHand ? LAST_INDEX_RIGHT_HAND - this.selectedFinger : this.selectedFinger
      this.onClickSelectFinger(finger)
      if (this.savedFingers.includes(this.selectedFinger)) {
        this.isReplaceEnabled = true
      }
    }
    this.isComeFromReplace = false
  },
  methods: {
    ...mapMutations([
      'setHandSelected',
      'setFingerSelected'
    ]),
    onClickSelectHand (hand) {
      this.selectedHand = hand
      if (!this.isComeFromReplace) {
        this.selectedFinger = this.selectedHand ? LAST_INDEX_RIGHT_HAND - this.getFirstEnableFinger() : this.getFirstEnableFinger()
      } else {
        this.selectedFinger = this.selectedHand ? LAST_INDEX_RIGHT_HAND - this.getFingerSelected : this.getFingerSelected
      }
      this.setHandSelected(this.selectedHand)
      if (this.onSelectHand) this.onSelectHand(this.selectedHand)
      if (this.onSelectFinger) this.onSelectFinger(this.selectedFinger)
      this.manageScanning()
    },
    onClickSelectFinger (finger) {
      this.selectedFinger = this.selectedHand ? LAST_INDEX_RIGHT_HAND - finger : finger
      this.setFingerSelected(finger)
      if (this.onSelectFinger) this.onSelectFinger(this.selectedFinger)
      this.manageScanning()
    },
    getFirstEnableFinger () {
      let firstEnableFinger = -1
      this.fingers.forEach(i => {
        if ((firstEnableFinger === -1) && (this.isFingerSelectionEnable(i.index))) {
          firstEnableFinger = i.index
        }
      })
      return firstEnableFinger
    },
    manageScanning () {
      if (this.selectedFinger < 0 || this.selectedFinger > 9) {
        this.$emit('stopScan')
      } else {
        this.$emit('forceScan')
      }
    },
    isFingerSelected (index) {
      return this.selectedHand ? this.selectedFinger === LAST_INDEX_RIGHT_HAND - index : this.selectedFinger === index
    },
    isFingerDone (index) {
      const multiSelectorIndex = this.selectedHand === 1 ? LAST_INDEX_RIGHT_HAND - index : index
      return this.savedFingers.includes(multiSelectorIndex) // Some compatibility problems with older browsers ?
    },
    isFingerSelectionEnable (index) {
      return ((!this.isReplaceEnabled && !this.isFingerDone(index)) || (this.isReplaceEnabled && this.isFingerDone(index)))
    },
    getIndexFinger (index) {
      return this.selectedHand ? LAST_INDEX_RIGHT_HAND - index : index
    },
    onClickReplace () {
      this.isReplaceEnabled = !this.isReplaceEnabled
      this.$emit('replaceClicked')
      this.onClickSelectFinger(this.getFirstEnableFinger())
    },
    onClickScan () {
      if (this.onScan) this.onScan()

      this.$emit('forceScan')
    },
    isIndexFinger (frontendValue) {
      return frontendValue === 1
    }
  }
}
</script>

<style lang="scss" scoped>
  .he-ui-scan-selection-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 95%;
    padding-right: 220px;
    padding-left: 50px;
    @media (max-width: 1180px) {
      padding-right: 185px;
    }
  }
  .he-ui-font-normal-bold {
    font-size: 22px;
  }
  .he-ui-predefined-value {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.59);
  }

  .he-ui-scan-fingers-hands-mini {
    display: flex;
    .he-ui-scan-fingers-hand-img {
      width: 217px;
      height: 217px;
      cursor: pointer;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 27px;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $grey-dark-color;
      font-size: .8rem;
      font-weight: 700;
      padding: 30px;
      &:first-child {
        margin-right: 16px;
      }
      img {
        max-height: 100%;
      }
      &.hand-selected {
        background: $blue-color;
        color: white;
      }
    }
  }
  @media (max-height: 780px) {
    .he-ui-scan-fingers-hand-img {
      width: 150px !important;
      height: 150px !important;
    }
    .he-ui-scan-fingers-button {
      width: 150px !important;
    }
  }
  .he-ui-scan-fingers-buttons {
    display: flex;
    gap: 9px;
    flex-wrap: wrap;
    .he-ui-scan-fingers-button {
      width: 217px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $grey-dark-color;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      padding: 10px;
      font-weight: 700;
      background: white;
      font-size: .8rem;
      cursor: pointer;
      &.selected {
        background: $blue-color !important;
        color: white !important;

        .he-ui-scan-fingers-button-index {
          background: white !important;
          color: $blue-color !important;
        }
      }
      &.done {
        background: $green-color-8-percent;
        color: $green-color;
        .he-ui-scan-fingers-button-index {
          background: $green-color;
          color: $white-color;
        }
      }
      &.finger-disabled {
        opacity: 0.5;
        cursor: default;
      }

      .he-ui-scan-fingers-button-index {
        background: $grey-dark-color;
        color: white;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        font-size: .7rem;
      }
    }
    .he-ui-star-done {
      filter: invert(65%) sepia(24%) saturate(1153%) hue-rotate(83deg) brightness(92%) contrast(97%);
    }
  }
</style>
