<template>
  <Header
    :headerText="headerText"
    :goBack="goBack"
    :showBars="isNewPatient"
  />
  <DefaultContainer
    :col1="isQualityResultImage ? 'col-5' : 'col-7'"
    :col2="isQualityResultImage ? 'col-7' : 'col-5'">
    <template #col1>
        <!-- before scanned -->
        <section v-if="!isQualityResultImage" class="col1 d-flex flex-column align-items-center justify-content-center">
            <div class="text-center mb-4 he-ui-preview-title">
              {{ $t('main.fingerprint_preview') }}
            </div>
            <div class="he-ui-fingerprint-preview">
              <preview
                class="he-ui-preview"
                :image="imageData"
                scanningComponent="true"
              />
            </div>
            <div class="d-flex flex-row">
              <Hand
                selectedHand=0
                :selectedFinger="selectedFinger"
                :savedFingers="savedFingers"
                bothHandsTogether="true"
              />
              <Hand
                selectedHand=1
                :selectedFinger="selectedFinger"
                :savedFingers="savedFingers"
                bothHandsTogether="true"
              />
            </div>
            <InfoBox
              class="he-ui-info-box mt-4"
              :status="infoBoxSelectedFingerStatus"
              :hand="selectedHand"
              :finger="selectedFinger"
              :multifingerInfo="true"
            />
            <InfoBox
              class="he-ui-info-box mt-2"
              status="predefined"
            />
        </section>
        <!-- after scanned -->
        <section v-if="isQualityResultImage" class="he-ui-after-scanned col1 d-flex flex-column align-items-center justify-content-center">
          <info-box
            class="he-ui-info-box mb-5"
            status="indications"
            :hand="selectedHand"
            :finger="selectedFinger"
          />
          <Hand
            :selectedHand="selectedHand"
            :selectedFinger="selectedFinger"
            :showNumberOfFingers="false"
            :showAllFingerprints="true"
            :showNotActiveFingerprints="false"
            :isNewPatient="false"
          />
        </section>
    </template>
    <template #col2>
      <section class="col2 d-flex flex-column h-100 justify-content-end"
        :class="{'he-ui-after-scanned': isQualityResultImage}"
      >
        <multi-selection
          v-if="!isQualityResultImage"
          :onSelectHand="onSelectHand"
          :onSelectFinger="onSelectFinger"
          :onClickEnd="onClickEnd"
          :savedFingers="savedFingers"
          :isNewPatient="isNewPatient"
          :disableEndButton="!areThereOneFingerOfEachHand"
          :fromReplace="isFromReplace"
          @forceScan="onScan"
          @stopScan="onStopScan"
          @replaceClicked="switchIsReplacing"
        />
        <scanning-results
          v-if="isQualityResultImage"
          :fingerprintImage="imageData"
          :fingerprintScore="scoreQuality"
          :onMultipleFingersPage="true"
          :onRegistering="isNewPatient"
          :hand="selectedHand"
          :finger="selectedFinger"
          :isReplacing="isReplacingEnabled"
          @scan="onScan"
          @replace="onReplace"
          @navigateToMultifinger="goToMultiFinger"
          @canLeave="setCanLeave"
        />
      </section>
    </template>
  </DefaultContainer>
  <portal to="modal-place">
    <LowQualityModal
      v-if="isLowQuality && isNewPatient"
      :onClicked="captureFingerprint"
    />
  </portal>
  <portal to="modal-place">
    <LeaveRegister
      v-if="showLeavingModal"
      :buttons="leavingModalButtons"
      :isNewPatient="isNewPatient"/>
  </portal>
</template>

<script>
import { FINGERS_DATA, HHID, IS_NEW_PATIENT } from '@/constants/constants'
import { MAIN_PAGE_ROUTE_NAME, STORED_FINGERPRINT_PAGE_ROUTE_NAME } from '@/router/routes'
import { mapActions } from 'vuex'
import DefaultContainer from '@/components/DefaultContainer'
import Hand from '@/components/Hand'
import Header from '@/components/Header'
import InfoBox from '@/components/InfoBox'
import LeaveRegister from '@/components/LeaveRegister'
import LowQualityModal from '@/components/LowQualityModal'
import MultiSelection from '@/components/MultiSelection'
import Preview from '@/components/Preview'
import ScanningResults from '@/components/ScanningResults'
import fingerprintReader from '@/composition/fingerprintReader'

export default {
  name: 'ScanMultipleFingersPage',
  components: {
    DefaultContainer,
    Hand,
    Header,
    InfoBox,
    LeaveRegister,
    LowQualityModal,
    MultiSelection,
    Preview,
    ScanningResults
  },
  beforeRouteLeave (to, from) {
    // Show exit confirm modal only if there are some fingerfrint saved
    if (!this.canLeavePage && this.savedFingers.length > 0) {
      this.showLeavingModal = true
      return false
    }
  },
  setup () {
    const { imageData, clean, captureFingerprint, hasResultImage, isLowQuality, resetFingerprint, scoreQuality, stopCapture } = fingerprintReader()

    return {
      imageData,
      clean,
      captureFingerprint,
      hasResultImage,
      isLowQuality,
      resetFingerprint,
      scoreQuality,
      stopCapture
    }
  },
  data () {
    return {
      infoBoxSelectedFingerStatus: 'initial',
      isFromReplace: false,
      isReplacingEnabled: false,
      isNewPatient: false,
      savedFingers: localStorage.getItem(FINGERS_DATA)
        ? JSON.parse(localStorage.getItem(FINGERS_DATA)).map(i => i.idFinger)
        : [],
      scanning: false,
      selectedHand: null,
      selectedFinger: null,
      showLeavingModal: false,
      patientId: localStorage.getItem(HHID) ? localStorage.getItem(HHID) : null,
      canLeavePage: false
    }
  },
  computed: {
    leavingModalButtons () {
      return [
        { text: this.isNewPatient ? this.$t('main.continue_registration') : this.$t('main.cancel'), template: 'white', handler: () => { this.showLeavingModal = false } },
        { text: this.$t('main.exit'), template: 'blue', handler: () => { this.hideAndLeavePage() } }
      ]
    },
    headerText () {
      return this.isNewPatient ? this.$t('main.new_patient') : this.$t('main.identification')
    },
    isQualityResultImage () {
      return this.hasResultImage && (this.isNewPatient ? !this.isLowQuality : true)
    },
    areThereOneFingerOfEachHand () {
      return (this.savedFingers.filter((i) => i < 5).length > 0 && this.savedFingers.filter((d) => d >= 5).length > 0)
    },
    getSavedFingersIndexes () {
      return localStorage.getItem(FINGERS_DATA)
        ? JSON.parse(localStorage.getItem(FINGERS_DATA)).map(i => i.idFinger)
        : []
    }
  },
  mounted () {
    this.isNewPatient = this.newPatient()
    this.retrieveFingerprintsForPatient()
    this.captureFingerprint()
  },
  methods: {
    ...mapActions([
      'fetchPatientFingerprints'
    ]),
    newPatient () {
      return localStorage.getItem(IS_NEW_PATIENT) ? localStorage.getItem(IS_NEW_PATIENT) : false
    },
    goBack () {
      return this.isQualityResultImage
        ? this.goToMultiFinger(this.savedFingers)
        : this.goToMainPage()
    },
    showConfirmMessage () {
      if (this.isNewPatient && this.savedFingers.length > 0) {
        this.showLeavingModal = true
      } else {
        this.goToMainPage()
      }
    },
    hideAndLeavePage () {
      this.showLeavingModal = false
      this.canLeavePage = true
      if (this.isNewPatient) {
        this.goToMainPage()
      } else {
        this.goToMainPage()
      }
    },
    goToMainPage () {
      this.$router.push({ name: MAIN_PAGE_ROUTE_NAME })
    },
    goToMultiFinger (savedFingers) {
      this.isFromReplace = false
      this.isReplacingEnabled = false
      this.savedFingers = savedFingers
      this.isNewPatient = this.newPatient()
      this.resetFingerprint()
      this.captureFingerprint()
    },
    setCanLeave () {
      this.canLeavePage = true
    },
    changeStatus () {
      this.scanning = false
    },
    onScan () {
      this.stopCapture()
      this.captureFingerprint()
    },
    onStopScan () {
      this.stopCapture()
    },
    onReplace (hand, finger) {
      this.isFromReplace = true
      this.onScan()
    },
    switchIsReplacing () {
      this.isReplacingEnabled = !this.isReplacingEnabled
    },
    onSelectHand (value) {
      this.selectedHand = value
    },
    setInfoBoxBySelectedFinger () {
      if (this.savedFingers.includes(this.selectedFinger)) {
        this.infoBoxSelectedFingerStatus = 'finished'
      } else {
        this.infoBoxSelectedFingerStatus = 'initial'
      }
    },
    onSelectFinger (value) {
      this.selectedFinger = value
      this.setInfoBoxBySelectedFinger()
    },
    retrieveFingerprintsForPatient () {
      if (this.onRegistering) {
        const backSavedFingers = JSON.parse(localStorage.getItem(FINGERS_DATA)).map(i => i.idFinger)
        this.navigateToOwnMultifingerReset(backSavedFingers)
      }
    },
    onClickEnd () {
      this.canLeavePage = true
      this.showLeavingModal = false

      this.$router.push({
        name: STORED_FINGERPRINT_PAGE_ROUTE_NAME
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .he-ui-preview-title {
    color: $grey-info-color;
    font-weight: 700;
    font-size: 22px;
  }
  .he-ui-fingerprint-preview {
    width: 189px;
    height: 189px;
    position: relative;
    top: 25px;
  }
  .he-ui-after-scanned {
    height: 111% !important;
  }

</style>
